// action types
import {
  UNAUTHORIZED_ACCESS,
  FETCH_ACCESS_TOKEN_SUCCESS,
  REQUEST_DOSESPOT_CREDENTIALS,
  FETCH_DOSESPOT_CREDENTIALS_SUCCEEDED,
  FETCH_DOSESPOT_CREDENTIALS_FAILED,
} from "../actions/actions";

const initialState = {
  isAuthorized: false,
  access_token: null,
  dosespot: {
    access_token: null,
  },
  fetched: false,
  fetching: false,
  error: null,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case UNAUTHORIZED_ACCESS: {
      state = {
        ...state,
        isAuthorized: false,
      };
      break;
    }
    case FETCH_ACCESS_TOKEN_SUCCESS: {
      state = {
        ...state,
        isAuthorized: true,
        access_token: action.access_token,
      };
      break;
    }
    case REQUEST_DOSESPOT_CREDENTIALS: {
      state = {
        ...state,
        fetching: true,
        fetched: false,
        error: null,
      };
      break;
    }
    case FETCH_DOSESPOT_CREDENTIALS_SUCCEEDED: {
      state = {
        ...state,
        fetching: false,
        fetched: true,
        dosespot: {
          access_token: action.data.access_token,
          ssoUri: action.data.uri,
        },
      };
      break;
    }
    case FETCH_DOSESPOT_CREDENTIALS_FAILED: {
      state = {
        ...state,
        fetching: false,
        fetched: true,
        error: action.error,
      };
      break;
    }
    default: {
      break;
    }
  }
  return state;
};

export default authReducer;
