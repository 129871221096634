import React from "react";
import { Field, FieldArray, ErrorMessage } from "formik";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import { withStyles } from "@material-ui/core/styles";
import { red } from "@material-ui/core/colors";
import {
  date,
  checkbox,
  text,
  phone,
  email,
  radio,
  boolean,
  dropdown,
  dateTime,
  async,
  creatable,
  decimal,
  integer,
  string,
  year,
} from "../forms/renderers/renderers";
import {
  phoneFieldValidator,
  emailValidator,
  required,
} from "../forms/validators";
import { errorClass, drugFrequencyOptions } from "../forms/consts";
import { getOptionsFromServer } from "../utils/asyncSettingsServerRequests";

const styles = theme => ({
  icon: {
    margin: theme.spacing.unit * 2,
  },
  iconHover: {
    margin: theme.spacing.unit * 2,
    ":hover": {
      color: red[800],
    },
  },
  paper: {
    minWidth: "fit-content",
    width: "80%",
  },
  select: {
    minWidth: "fit-content",
  },
  container: {
    flexGrow: 1,
    padding: theme.spacing.unit * 2,
  },
  error: {
    color: "red",
  },
  avatar: {
    width: "4em",
    height: "4em",
  },
  tooltip: {
    fontSize: "2em",
  },
  checked: {},
});

class QuestionnaireItem extends React.Component {
  isPhoneType = item => {
    if (Array.isArray(item.code) && item.code.length > 0) {
      if (item.code[0].code === "phoneNumber") {
        return true;
      }
    }
    return false;
  };

  isEmailType = item => {
    if (Array.isArray(item.code) && item.code.length > 0) {
      if (item.code[0].code === "email") {
        return true;
      }
    }
    return false;
  };

  isYearType = item => {
    if (Array.isArray(item.code) && item.code.length > 0) {
      if (item.code[0].code === "year") {
        return true;
      }
    }
    return false;
  };

  isDrugType = item => {
    if (
      Array.isArray(item.code) &&
      item.code.length > 0 &&
      Array.isArray(item.item) &&
      item.item.length === 4
    ) {
      if (item.code[0].code === "ndc") {
        return true;
      }
    }
    return false;
  };

  isAutoCompleteField = item => {
    let isAutoComplete = false;
    if (Array.isArray(item.extension) && item.extension.length > 0) {
      item.extension.some(extension => {
        if (
          extension.url ===
            "http://hl7.org/fhir/StructureDefinition/questionnaire-itemControl" &&
          extension.valueCodeableConcept &&
          Array.isArray(extension.valueCodeableConcept.coding) &&
          extension.valueCodeableConcept.coding.length > 0 &&
          extension.valueCodeableConcept.coding[0].system ===
            "http://hl7.org/fhir/questionnaire-item-control" &&
          extension.valueCodeableConcept.coding[0].code === "autocomplete"
        ) {
          isAutoComplete = true;
          return true;
        } else {
          return false;
        }
      });
    }
    return isAutoComplete;
  };

  getOptions = item => {
    let options = [];
    if (Array.isArray(item.option) && item.option.length > 0) {
      item.option.forEach(option => {
        let score;
        if (Array.isArray(option.extension) && option.extension.length > 0) {
          if (
            option.extension[0].url ===
            "http://hl7.org/fhir/StructureDefinition/questionnaire-ordinalValue"
          ) {
            score = option.extension[0].valueDecimal;
          }
        }
        if (option.valueCoding) {
          // console.debug("option valueCoding", option);
          options.push({
            value: option.valueCoding.code,
            label: option.valueCoding.display,
            system: option.valueCoding.system,
            score: score,
            option: option,
          });
        } else if (option.valueInteger) {
          options.push({
            value: option.valueInteger,
            label: option.valueInteger,
            score: score,
            option: option,
          });
        } else if (option.valueString) {
          options.push({
            value: option.valueString,
            label: option.valueString,
            score: score,
            option: option,
          });
        }
      });
    } else if (item.type === "boolean") {
      options.push({ value: "true", label: "Yes" });
      options.push({ value: "false", label: "No" });
    }
    return options;
  };

  getExternallyDefinedOptionsUrl = item => {
    let urlPath;
    if (Array.isArray(item.extension) && item.extension.length > 0) {
      item.extension.some(extension => {
        if (
          extension.url ===
          "http://hl7.org/fhir/StructureDefinition/questionnaire-externallydefined"
        ) {
          let url = new URL(extension.valueUri);
          urlPath = url.pathname;
          return true;
        }
        return false;
      });
    }
    return urlPath;
  };

  getAsyncOptions = (item, searchTerm) => {
    let urlPath;
    if (item.type !== "choice" && item.type !== "open-choice") {
      return;
    }
    if (!searchTerm) {
      return;
    }
    urlPath = this.getExternallyDefinedOptionsUrl(item);
    if (!urlPath) {
      return [];
    }
    return new Promise(async resolve => {
      let optionsResponse = await getOptionsFromServer(searchTerm, urlPath);
      if (optionsResponse.code < 400 && Array.isArray(optionsResponse.data)) {
        resolve(optionsResponse.data);
      } else {
        resolve([]);
      }
    });
  };

  selectOptionDisplayType = item => {
    if (
      !Array.isArray(item.option) ||
      (Array.isArray(item.option) &&
        item.option.length > 4 &&
        !this.isScoreType(item))
    ) {
      return dropdown;
    }
    if (item.maxLength > 1) {
      return checkbox;
    }
    return radio;
  };

  getComponentForType = (type, maxLength, item) => {
    switch (type) {
      case "string": {
        if (this.isPhoneType(item)) return phone;
        if (this.isEmailType(item)) return email;
        if (Array.isArray(item.option) && item.option.length > 0) {
          return this.selectOptionDisplayType(item);
        }
        return string;
      }
      case "text": {
        if (Array.isArray(item.option) && item.option.length > 0) {
          return this.selectOptionDisplayType(item);
        }
        return text;
      }
      case "date": {
        if (this.isYearType(item)) return year;
        if (Array.isArray(item.option) && item.option.length > 0) {
          return this.selectOptionDisplayType(item);
        }
        return date;
      }
      case "dateTime": {
        if (Array.isArray(item.option) && item.option.length > 0) {
          return this.selectOptionDisplayType(item);
        }
        return dateTime;
      }
      case "choice": {
        if (this.isAutoCompleteField(item)) return async;
        return this.selectOptionDisplayType(item);
      }
      case "open-choice": {
        return creatable;
      }
      case "boolean": {
        return boolean;
      }
      case "decimal": {
        if (Array.isArray(item.option) && item.option.length > 0) {
          return this.selectOptionDisplayType(item);
        }
        return decimal;
      }
      case "integer": {
        if (Array.isArray(item.option) && item.option.length > 0) {
          return this.selectOptionDisplayType(item);
        }
        return integer;
      }
      default: {
        break;
      }
    }
  };

  isScoreType = item => {
    if (
      Array.isArray(item.option) &&
      item.option.length > 0 &&
      Array.isArray(item.option[0].extension) &&
      item.option[0].extension.length > 0 &&
      item.option[0].extension[0].url ===
        "http://hl7.org/fhir/StructureDefinition/questionnaire-ordinalValue"
    ) {
      return true;
    }
    return false;
  };

  // RepeatingItem = ({ item }) => {
  //   return (
  //     // <FieldArray
  //     //   name={item.linkId}
  //     //   render={arrayHelpers => (
  //     //     <React.Fragment>
  //     //       <Grid item xs={12}>
  //     //         <Field
  //     //           fullWidth
  //     //           name={`${item.linkId}.0`}
  //     //           component={this.getComponentForType(
  //     //             item.type,
  //     //             item.maxLength,
  //     //             item
  //     //           )}
  //     //           label={item.text}
  //     //           required={item.required ? item.required : false}
  //     //           options={this.getOptions(item)}
  //     //           disabled={item.readOnly}
  //     //           validate={
  //     //             item.required
  //     //               ? required("Required")
  //     //               : this.isEmailType(item)
  //     //               ? emailValidator("Invalid Email")
  //     //               : this.isPhoneType(item)
  //     //               ? phoneFieldValidator("Invalid")
  //     //               : null
  //     //           }
  //     //         />
  //     //         <ErrorMessage
  //     //           name={`${item.linkId}.0`}
  //     //           component="span"
  //     //           className={errorClass}
  //     //         />
  //     //       </Grid>
  //     //       {this.props.formProps.values[item.linkId] &&
  //     //         this.props.formProps.values[item.linkId].length > 1 &&
  //     //         this.props.formProps.values[item.linkId].map((field, index) => (
  //     //           <React.Fragment key={`${item.linkId}.${index}`}>
  //     //             {index >= 1 && (
  //     //               <Grid item xs={12}>
  //     //                 <Field
  //     //                   fullWidth
  //     //                   name={`${item.linkId}.${index}`}
  //     //                   component={this.getComponentForType(
  //     //                     item.type,
  //     //                     item.maxLength,
  //     //                     item
  //     //                   )}
  //     //                   label={item.text}
  //     //                   required={item.required ? item.required : false}
  //     //                   options={this.getOptions(item)}
  //     //                   disabled={item.readOnly}
  //     //                   validate={
  //     //                     item.required
  //     //                       ? required("Required")
  //     //                       : this.isEmailType(item)
  //     //                       ? emailValidator("Invalid Email")
  //     //                       : this.isPhoneType(item)
  //     //                       ? phoneFieldValidator("Invalid")
  //     //                       : null
  //     //                   }
  //     //                 />
  //     //                 <ErrorMessage
  //     //                   name={`${item.linkId}.${index}`}
  //     //                   component="span"
  //     //                   className={errorClass}
  //     //                 />
  //     //               </Grid>
  //     //             )}
  //     //             {field && (
  //     //               <Grid item xs={12}>
  //     //                 <Button
  //     //                   size="small"
  //     //                   color="secondary"
  //     //                   aria-label="remove"
  //     //                   onClick={() => arrayHelpers.remove(index)}
  //     //                   className="btn btn-blue"
  //     //                 >
  //     //                   Remove Item
  //     //                 </Button>
  //     //               </Grid>
  //     //             )}
  //     //           </React.Fragment>
  //     //         ))}
  //     //       {this.props.formProps.values[item.linkId] && (
  //     //         <Grid item xs={12}>
  //     //           <Button
  //     //             size="small"
  //     //             color="secondary"
  //     //             aria-label="add"
  //     //             onClick={() =>
  //     //               arrayHelpers.insert(
  //     //                 this.props.formProps.values[item.linkId].length
  //     //               )
  //     //             }
  //     //             className="btn btn-blue"
  //     //           >
  //     //             Add Item
  //     //           </Button>
  //     //         </Grid>
  //     //       )}
  //     //     </React.Fragment>
  //     //   )}
  //     // />
  //     <FieldArray
  //       name={item.linkId}
  //       render={arrayHelpers => (
  //         <React.Fragment>
  //           {/* {console.debug("repeats", item)}
  //                       {item.type !== "group" && item.type !== "display" && (
  //                         <this.RenderItem
  //                           item={item}
  //                           name={`${item.linkId}.0`}
  //                         />
  //                       )}
  //                       <this.GroupDisplayItem item={item} name={`${item.linkId}.0`} /> */}
  //           {this.props.formProps.values[item.linkId] &&
  //             this.props.formProps.values[item.linkId].length > 0 &&
  //             this.props.formProps.values[item.linkId].map((field, index) => (
  //               <React.Fragment key={`${item.linkId}.${index}`}>
  //                 {/* {index >= 1 && (
  //                                 <React.Fragment> */}
  //                 {item.type !== "group" && item.type !== "display" && (
  //                   <this.RenderItem
  //                     item={item}
  //                     name={`${item.linkId}.${index}`}
  //                   />
  //                 )}
  //                 {(item.type === "group" || item.type === "display") && (
  //                   <this.GroupDisplayItem
  //                     item={item}
  //                     name={`${item.linkId}.${index}`}
  //                   />
  //                 )}
  //                 {/* </React.Fragment>
  //                               )} */}
  //                 {this.props.formProps.values[item.linkId].length > 1 && (
  //                   <Grid item xs={12}>
  //                     <Button
  //                       size="small"
  //                       color="secondary"
  //                       aria-label="remove"
  //                       onClick={() => arrayHelpers.remove(index)}
  //                       className="btn btn-blue"
  //                     >
  //                       Remove Item
  //                     </Button>
  //                   </Grid>
  //                 )}
  //               </React.Fragment>
  //             ))}
  //           <Grid item xs={12}>
  //             <Button
  //               size="small"
  //               color="secondary"
  //               aria-label="add"
  //               onClick={() =>
  //                 arrayHelpers.insert(
  //                   this.props.formProps.values[item.linkId]
  //                     ? this.props.formProps.values[item.linkId].length
  //                     : null
  //                 )
  //               }
  //               className="btn btn-blue"
  //             >
  //               Add {item.type === "group" ? item.linkId : item.text}
  //             </Button>
  //           </Grid>
  //         </React.Fragment>
  //       )}
  //     />
  //   );
  // };

  // Keep for now - "enableWhen" logic
  // enablebyPropHasValue = (enableByPropName, childNameWithIndex) => {
  //   let index = 0;
  //   let parentName = childNameWithIndex;
  //   if (parentName.includes(".")) {
  //     const nameSplit = parentName.split(".");
  //     parentName = nameSplit[0];
  //     index =
  //       Number.parseInt(nameSplit[1]) === isNaN
  //         ? 0
  //         : Number.parseInt(nameSplit[1]);
  //   }

  //   const parentValue = this.props.formProps.values[parentName];
  //   if (
  //     Array.isArray(parentValue) &&
  //     parentValue.length > index &&
  //     parentValue[index] != null &&
  //     typeof parentValue[index] === "object"
  //   ) {
  //     return (
  //       parentValue[index][enableByPropName] != null &&
  //       (typeof parentValue[index][enableByPropName] === "object" ||
  //         (typeof parentValue[index][enableByPropName] === "string" &&
  //           parentValue[index][enableByPropName].length > 0))
  //     );
  //   }

  //   return false;
  // };

  RenderItem = ({ item, name }) => {
    let options = this.getOptions(item);
    let disabledBasedOnProp = "";
    if (Array.isArray(item.enableWhen) && item.enableWhen.length > 0) {
      disabledBasedOnProp = item.enableWhen[0].question;
    }
    return (
      <Grid item xs={12} key={name ? `${name}.${item.linkId}` : item.linkId}>
        <Field
          fullWidth
          name={name ? `${name}.${item.linkId}` : item.linkId}
          component={this.getComponentForType(item.type, item.maxLength, item)}
          label={item.text}
          required={item.required ? item.required : false}
          options={options}
          loadOptions={search => this.getAsyncOptions(item, search)}
          disabled={
            item.readOnly /* ? true : disabledBasedOnProp ? this.props.formProps.values[disabledBasedOnProp] : false*/
          }
          isMulti={item.maxLength && item.maxLength > 1}
          helpertext={
            this.props.helpers[`${item.linkId}-help`]
              ? this.props.helpers[`${item.linkId}-help`]
              : Array.isArray(options) &&
                options.length > 0 &&
                this.getExternallyDefinedOptionsUrl(item)
              ? "Select from options or type to find more"
              : null
          }
          validate={
            item.required
              ? required
              : this.isEmailType(item)
              ? emailValidator
              : this.isPhoneType(item)
              ? phoneFieldValidator
              : null
          }
        />
        <ErrorMessage
          name={name ? `${name}.${item.linkId}` : item.linkId}
          component="span"
          className={errorClass}
        />
      </Grid>
    );
  };

  GroupDisplayItem = ({ item, name, index }) => {
    if (this.isDrugType(item)) {
      return (
        <React.Fragment>
          <Grid item xs={12}>
            <Field
              fullWidth
              name={`${name}.drugName`}
              component={creatable}
              label={item.item[0].text}
              required={item.item[0].required ? item.item[0].required : false}
              loadOptions={search => this.getAsyncOptions(item.item[0], search)}
              disabled={item.item[0].readOnly}
              onChange={value => {
                this.props.formProps.setFieldValue(`${name}.drugName`, value);
                this.props.formProps.setFieldValue(`${name}.drugStrength`, "");
                this.props.formProps.setFieldValue(`${name}.ndc`, "");
              }}
            />
            <ErrorMessage
              name={`${name}.drugName`}
              component="span"
              className={errorClass}
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              fullWidth
              name={`${name}.drugStrength`}
              component={dropdown}
              disabled={
                !this.props.formProps.values[item.linkId][index] ||
                !this.props.formProps.values[item.linkId][index].drugName
              }
              label={item.item[1].text}
              required={item.item[1].required ? item.item[1].required : false}
              options={
                Array.isArray(this.props.formProps.values[item.linkId]) &&
                this.props.formProps.values[item.linkId][index] &&
                this.props.formProps.values[item.linkId][index].drugName &&
                Array.isArray(
                  this.props.formProps.values[item.linkId][index].drugName
                    .strengthOptions
                )
                  ? this.props.formProps.values[item.linkId][
                      index
                    ].drugName.strengthOptions.map(value => {
                      return {
                        value: value,
                        label: value.Strength,
                      };
                    })
                  : []
              }
              onChange={value => {
                let strength =
                  value && value.value && value.value.Strength
                    ? value.value.Strength
                    : value;
                let ndc =
                  value && value.value && value.value.NDC
                    ? value.value.NDC
                    : null;
                this.props.formProps.setFieldValue(`${name}.drugStrength`, {
                  value: strength,
                  label: value.label,
                });
                this.props.formProps.setFieldValue(`${name}.ndc`, {
                  value: ndc,
                  label: ndc,
                });
              }}
            />
            <ErrorMessage
              name={`${name}.drugStrength`}
              component="span"
              className={errorClass}
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              fullWidth
              name={`${name}.drugFrequency`}
              component={dropdown}
              label={item.item[2].text}
              required={item.item[2].required ? item.item[2].required : false}
              options={drugFrequencyOptions}
              disabled={
                !this.props.formProps.values[item.linkId][index] ||
                !this.props.formProps.values[item.linkId][index].drugName
              }
            />
            <ErrorMessage
              name={`${name}.drugFrequency`}
              component="span"
              className={errorClass}
            />
          </Grid>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          {Array.isArray(item.item) && item.item.length > 0
            ? item.item.map((subItem, index) => (
                <React.Fragment key={subItem.linkId}>
                  {(subItem.type === "group" || subItem.type === "display") && (
                    <this.TopLevelItemRender item={subItem} />
                  )}
                  {subItem.type !== "group" && subItem.type !== "display" && (
                    <this.RenderItem item={subItem} name={name} />
                  )}
                </React.Fragment>
              ))
            : null}
        </React.Fragment>
      );
    }
  };

  TopLevelItemRender = props => {
    const { item } = props;

    const getAddItemText = item => {
      switch (item.definition) {
        case "AllergyIntolerance_3_0_1":
          return "Allergies";

        case "Condition_3_0_1":
          return "Conditions";

        default:
          if (item.type === "string" || item.type === "text") {
            return "additional response";
          }
          return item.type === "group" ? item.linkId : item.text;
      }
    };

    return (
      <React.Fragment>
        {Array.isArray(item) && item.length > 0
          ? item.map((item, topLevelIndex) => (
              <React.Fragment key={item.linkId}>
                {item.type === "group" && (
                  <h3 className="pt-4 text-lg">{item.text}</h3>
                )}
                {item.type === "display" && (
                  <h2 className="pt-4 text-lg font-bold">{item.text}</h2>
                )}
                {!item.repeats && (
                  <React.Fragment>
                    {item.type !== "group" && item.type !== "display" && (
                      <this.RenderItem item={item} />
                    )}
                    {Array.isArray(item.item) && item.item.length > 0
                      ? item.item.map(subItem => (
                          <React.Fragment key={subItem.linkId}>
                            {(subItem.type === "group" ||
                              subItem.type === "display") && (
                              <React.Fragment>
                                <h3 className="pt-4 text-md">{subItem.text}</h3>
                                <this.GroupDisplayItem item={subItem} />
                              </React.Fragment>
                            )}
                            {subItem.type !== "group" &&
                              subItem.type !== "display" && (
                                <this.RenderItem item={subItem} />
                              )}
                          </React.Fragment>
                        ))
                      : null}
                  </React.Fragment>
                )}
                {item.repeats && (
                  <FieldArray
                    name={item.linkId}
                    render={arrayHelpers => (
                      <React.Fragment>
                        {/*{item.type !== "group" && item.type !== "display" && (
                          <this.RenderItem
                            item={item}
                            name={`${item.linkId}.0`}
                          />
                        )}
                        <this.GroupDisplayItem item={item} name={`${item.linkId}.0`} /> */}
                        {this.props.formProps.values[item.linkId] &&
                          this.props.formProps.values[item.linkId].length > 0 &&
                          this.props.formProps.values[item.linkId].map(
                            (field, index) => (
                              <React.Fragment key={`${item.linkId}.${index}`}>
                                {item.type !== "group" &&
                                  item.type !== "display" && (
                                    <this.RenderItem
                                      item={item}
                                      name={`${item.linkId}.${index}`}
                                    />
                                  )}
                                {(item.type === "group" ||
                                  item.type === "display") && (
                                  <this.GroupDisplayItem
                                    item={item}
                                    name={`${item.linkId}.${index}`}
                                    index={index}
                                  />
                                )}
                                {this.props.formProps.values[item.linkId]
                                  .length > 1 && (
                                  <Grid item xs={12}>
                                    <Button
                                      size="small"
                                      color="secondary"
                                      aria-label="remove"
                                      onClick={() => arrayHelpers.remove(index)}
                                      className="btn btn-blue"
                                    >
                                      Remove Response
                                    </Button>
                                  </Grid>
                                )}
                              </React.Fragment>
                            )
                          )}
                        <Grid item xs={12}>
                          <Button
                            size="small"
                            color="secondary"
                            aria-label="add"
                            onClick={() =>
                              arrayHelpers.insert(
                                this.props.formProps.values[item.linkId]
                                  ? this.props.formProps.values[item.linkId]
                                      .length
                                  : null,
                                ""
                              )
                            }
                            className="btn btn-blue"
                          >
                            Add{" " + getAddItemText(item)}
                          </Button>
                        </Grid>
                      </React.Fragment>
                    )}
                  />
                )}
                {topLevelIndex !== props.item.length - 1 && (
                  <Divider className="w-full" style={{ marginTop: "0.5rem" }} />
                )}
              </React.Fragment>
            ))
          : null}
      </React.Fragment>
    );
  };

  render() {
    const { item } = this.props;
    return (
      // <React.Fragment>
      <this.TopLevelItemRender item={item} />
      /* {Array.isArray(item) && item.length > 0
          ? item.map(item => (
              <React.Fragment key={item.linkId}>
                {(item.type === "group" || item.type === "display") && (
                  <this.RenderDisplayItem item={item} />
                )}
                {item.type !== "group" && item.type !== "display" && (
                  <this.RenderItem item={item} />
                )}
              </React.Fragment>
            ))
          : null} */
      // </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    organization: state.settings.organization,
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(QuestionnaireItem));
