import { put, takeLatest, all, call } from "redux-saga/effects";
import axios from "axios";
import { setAsyncRequestAuthorization } from "../utils/asyncServerRequests";
import { setAsyncSettingsRequestAuthorization } from "../utils/asyncSettingsServerRequests";
import { parseFhirBundle } from "../utils/resources";
import {
  REQUEST_SCHEDULE,
  FETCH_SCHEDULE_SUCCEEDED,
  FETCH_SCHEDULE_FAILED,
  REQUEST_PATIENTS,
  FETCH_PATIENTS_SUCCEEDED,
  FETCH_PATIENTS_FAILED,
  FETCH_PRACTITIONERS_SUCCEEDED,
  FETCH_PRACTITIONERS_FAILED,
  REQUEST_PRACTITIONERS,
  FETCH_USER_FAILED,
  FETCH_USER_SUCCEEDED,
  REQUEST_USER,
  REQUEST_ORGANIZATION,
  FETCH_ORGANIZATION_SUCCEEDED,
  FETCH_ORGANIZATION_FAILED,
  REQUEST_EVENTS,
  FETCH_EVENTS_SUCCEEDED,
  FETCH_EVENTS_FAILED,
  UNAUTHORIZED_ACCESS,
  FETCH_ACCESS_TOKEN_SUCCESS,
  REQUEST_DOSESPOT_CREDENTIALS,
  FETCH_DOSESPOT_CREDENTIALS_SUCCEEDED,
  FETCH_DOSESPOT_CREDENTIALS_FAILED,
} from "../actions/actions";
import moment from "moment";
import axiosRetry from "axios-retry";
import { getAccessToken } from "../utils/asyncSettingsServerRequests";
import store from "../store";

let token;

const fhirInstance = axios.create({
  baseURL: process.env.REACT_APP_FHIR_ENDPOINT,
  timeout: 50000,
  headers: {
    "Content-Type": "application/fhir+json",
    // Cache: "no-cache",
  },
});

axiosRetry(fhirInstance, {
  retries: 1,
  retryCondition: async error => {
    if (error.response && error.response.status === 401) {
      let jwtResponse = await getAccessToken();
      if (jwtResponse && jwtResponse.code < 400) {
        store.dispatch({
          type: FETCH_ACCESS_TOKEN_SUCCESS,
          access_token: jwtResponse.access_token,
        });
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  },
  retryDelay: () => {
    return 100;
  },
});

const settingsInstance = axios.create({
  baseURL: process.env.REACT_APP_SETTINGS_ENDPOINT,
  timeout: 50000,
  headers: {
    "Content-Type": "application/json",
    // Cache: "no-cache",
  },
});

axiosRetry(settingsInstance, {
  retries: 1,
  retryCondition: async error => {
    if (error.response && error.response.status === 401) {
      let jwtResponse = await getAccessToken();
      if (jwtResponse && jwtResponse.code < 400) {
        store.dispatch({
          type: FETCH_ACCESS_TOKEN_SUCCESS,
          access_token: jwtResponse.access_token,
        });
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  },
  retryDelay: () => {
    return 100;
  },
});

const setAuthorization = newToken => {
  token = newToken;
  fhirInstance.interceptors.request.use(config => {
    config.headers.Authorization = "Bearer " + token;
    return config;
  });
  settingsInstance.interceptors.request.use(config => {
    config.headers.Authorization = "Bearer " + token;
    return config;
  });
};

// const hintProviderInstance = axios.create({
//   baseURL: process.env.REACT_APP_HINT_PROVIDER_ENDPOINT,
//   timeout: 50000,
//   headers: {
//     "Content-Type": "application/json",
//     // Cache: "no-cache",
//     Authorization: `Bearer z4nPMM1nyIyeQI2toLoijkmEVwvzXaq9pvBQ`,
//     // Origin: "https://320a15fc.ngrok.io",
//   },
// });

// Our worker Saga: will perform the async increment task
export function* requestSchedule(action) {
  try {
    // setAuthorization();
    let scheduleResponse = yield call(
      fhirInstance.get,
      `/3_0_1/schedule?${action.payload.practitionerQuery}`
    );
    let schedules = parseFhirBundle(scheduleResponse.data);
    let startDate = moment(action.payload.date)
      .startOf("month")
      .subtract(7, "days")
      .format("YYYY-MM-DDTHH:MM");
    let endDate = moment(action.payload.date)
      .endOf("month")
      .add(7, "days")
      .format("YYYY-MM-DDTHH:MM");
    let slotResponse = yield call(
      fhirInstance.get,
      `/3_0_1/slot?schedule=Schedule/${schedules[0].id}&start=ge${startDate}&start=le${endDate}`
    );
    // get slot data from slot Bundle
    let slots = parseFhirBundle(slotResponse.data);
    let appointments = [];
    if (action.getAppointments) {
      let appointmentResponse = yield call(
        fhirInstance.get,
        `/3_0_1/appointment?date=ge${startDate}&date=le${endDate}`
      );
      // get appointment data from appointment Bundle
      appointments = parseFhirBundle(appointmentResponse.data);
    }
    yield put({
      type: FETCH_SCHEDULE_SUCCEEDED,
      slots: slots,
      scheduleId: schedules[0].id,
      appointments: appointments,
    });
  } catch (error) {
    if (error.response && error.response.status === 401) {
      yield setUnauthorizedStatus();
    }
    yield put({ type: FETCH_SCHEDULE_FAILED, error: error.message });
  }
}

export function* requestPatients(action) {
  try {
    // setAuthorization();
    let patientResponse = yield call(
      fhirInstance.get,
      `/3_0_1/patient?${action.payload}`
    );
    let patients = parseFhirBundle(patientResponse.data);
    // patients = getPatientId(patients);
    yield put({ type: FETCH_PATIENTS_SUCCEEDED, patients: patients });
  } catch (error) {
    if (error.response && error.response.status === 401) {
      yield setUnauthorizedStatus();
    }
    yield put({ type: FETCH_PATIENTS_FAILED, error: error.message });
  }
}

export function* requestPractitioners(action) {
  try {
    // setAuthorization();
    let practitionerResponse = yield call(
      fhirInstance.get,
      `/3_0_1/practitioner?active=true`
    );
    let practitioners = parseFhirBundle(practitionerResponse.data);
    yield put({
      type: FETCH_PRACTITIONERS_SUCCEEDED,
      practitioners: practitioners,
    });
  } catch (error) {
    if (error.response && error.response.status === 401) {
      yield setUnauthorizedStatus();
    }
    yield put({ type: FETCH_PRACTITIONERS_FAILED, error: error.message });
  }
}

export function* requestUser(action) {
  try {
    // setAuthorization();
    let userResponse = yield call(
      settingsInstance.get,
      `/user?${action.payload}`
    );
    let user = userResponse.data;
    yield put({
      type: FETCH_USER_SUCCEEDED,
      user: user,
    });
  } catch (error) {
    if (error.response && error.response.status === 401) {
      yield setUnauthorizedStatus();
    }
    yield put({ type: FETCH_USER_FAILED, error: error.message });
  }
}

export function* requestOrganization(action) {
  try {
    // setAuthorization();
    let organizationResponse = yield call(
      settingsInstance.get,
      `/organization?_id=${action.organizationId}`
    );
    requestDosespotCredentials();
    let organization = organizationResponse.data;
    const userResponse = yield call(
      settingsInstance.post,
      `user/getTeam`,
      organization.team
    );
    organization.team = userResponse.data;
    yield put({
      type: FETCH_ORGANIZATION_SUCCEEDED,
      organization: organization,
    });
  } catch (error) {
    if (error.response && error.response.status === 401) {
      yield setUnauthorizedStatus();
    }
    yield put({ type: FETCH_ORGANIZATION_FAILED, error: error.message });
  }
}

export function* requestEvents(action) {
  try {
    // setAuthorization();
    // let eventIds = [];
    // if (action.user) {
    //   let organizationResponse = yield call(
    //     settingsInstance.get,
    //     `/organization?_id=${action.user.organizationId}`
    //   );
    //   const organization = organizationResponse.data;
    //   eventIds = organization.eventIds;
    // } else if (action.publicLink) {
    //   let organizationResponse = yield call(
    //     settingsInstance.get,
    //     `/organization?publicLink=${action.publicLink}`
    //   );
    //   const organization = organizationResponse.data;
    //   eventIds = organization.eventIds;
    // } else {
    //   eventIds = action.payload;
    // }
    let eventResponse = yield call(settingsInstance.get, `/event/all`);
    let events = eventResponse.data;
    yield put({
      type: FETCH_EVENTS_SUCCEEDED,
      events: events,
    });
  } catch (error) {
    if (error.response && error.response.status === 401) {
      yield setUnauthorizedStatus();
    }
    yield put({ type: FETCH_EVENTS_FAILED, error: error.message });
  }
}

const getOrganizationState = state => state.settings.organization.dosespot;

export function* requestDosespotCredentials(action) {
  try {
    let payload;
    if (action && action.payload) {
      payload = action.payload;
    } else {
      // let organization = yield select(getOrganizationState);
      // if (!organization.id || !organization.key || !organization.adminUserId) {
      //   return;
      // }
      payload = {
        clinicKey: `${process.env.REACT_APP_PATIENT_DOSESPOT_CLINIC_KEY}`, // organization ? organization.key : null,
        clinicId: process.env.REACT_APP_PATIENT_DOSESPOT_CLINIC_ID, // organization ? organization.id : null,
        userId: process.env.REACT_APP_PATIENT_DOSESPOT_USER_ID, //organization.patientUserId,
      };
    }
    let dosespotResponse = yield call(settingsInstance.post, `/auth/dosespot`, {
      ...payload,
    });
    yield put({
      type: FETCH_DOSESPOT_CREDENTIALS_SUCCEEDED,
      data: dosespotResponse.data,
    });
  } catch (error) {
    yield put({
      type: FETCH_DOSESPOT_CREDENTIALS_FAILED,
      error: error.message,
    });
  }
}

export function* setUnauthorizedStatus() {
  yield put({ type: UNAUTHORIZED_ACCESS });
}

export function setAuthHeader(action) {
  setAuthorization(action.access_token);
  setAsyncRequestAuthorization(action.access_token);
  setAsyncSettingsRequestAuthorization(action.access_token);
}

// Our watcher Sagas
// Spawn a new requestSchedule task on each REQUEST_SCHEDULE
export function* watchRequestSchedule() {
  yield takeLatest(REQUEST_SCHEDULE, requestSchedule);
}

export function* watchRequestPatients() {
  yield takeLatest(REQUEST_PATIENTS, requestPatients);
}

export function* watchRequestPractitioners() {
  yield takeLatest(REQUEST_PRACTITIONERS, requestPractitioners);
}

export function* watchRequestUser() {
  yield takeLatest(REQUEST_USER, requestUser);
}

export function* watchRequestOrganization() {
  yield takeLatest(REQUEST_ORGANIZATION, requestOrganization);
}

export function* watchRequestEvents() {
  yield takeLatest(REQUEST_EVENTS, requestEvents);
}

export function* watchAccessTokenSet() {
  yield takeLatest(FETCH_ACCESS_TOKEN_SUCCESS, setAuthHeader);
}

export function* watchRequestDosespot() {
  yield takeLatest(REQUEST_DOSESPOT_CREDENTIALS, requestDosespotCredentials);
}

// export function* watchCreateSlotAppointment() {
//   yield all(REQUEST_CREATE_SLOT_APPOINTMENT, createSlotAppointment);
// }

// notice how we now only export the rootSaga
// single entry point to start all Sagas at once
export default function* rootSaga() {
  yield all([
    watchRequestSchedule(),
    watchRequestPatients(),
    watchRequestPractitioners(),
    watchRequestUser(),
    watchRequestOrganization(),
    watchRequestEvents(),
    watchAccessTokenSet(),
    watchRequestDosespot(),
  ]);
}
