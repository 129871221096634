import axios from "axios";
import {
  getPractitionerEmail,
  getOfficialName,
  getTelecomInfo,
} from "./resources";
import moment from "moment";
import { getMedicationOptions, getAllergyOptions } from "./asyncServerRequests";
import { stateOptions, relationshipTypes, languages } from "../forms/consts";
import store from "../store";
import { FETCH_ACCESS_TOKEN_SUCCESS } from "../actions/actions";
import axiosRetry from "axios-retry";

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

let token;

const instance = axios.create({
  baseURL: process.env.REACT_APP_SETTINGS_ENDPOINT,
  timeout: 50000,
  headers: {
    "Content-Type": "application/json",
    // Cache: "no-cache",
  },
});

const serverInstance = axios.create({
  baseURL: process.env.REACT_APP_FHIR_ENDPOINT,
  timeout: 10000,
  headers: {
    "Content-Type": "application/fhir+json",
    // Cache: "no-cache",
  },
});

axiosRetry(instance, {
  retries: 1,
  retryCondition: async error => {
    if (error.response && error.response.status === 401) {
      let jwtResponse = await getAccessToken();
      if (jwtResponse && jwtResponse.code < 400) {
        store.dispatch({
          type: FETCH_ACCESS_TOKEN_SUCCESS,
          access_token: jwtResponse.access_token,
        });
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  },
  retryDelay: () => {
    return 100;
  },
});

const snomedInstance = axios.create({
  baseURL:
    "https://snowstorm-fhir.snomedtools.org/snowstorm/snomed-ct/browser/MAIN/SNOMEDCT-US/2020-03-01/descriptions",
  timeout: 10000,
  headers: {
    "Content-Type": "application/json",
  },
});

export const setAsyncSettingsRequestAuthorization = newToken => {
  const token = newToken;
  instance.interceptors.request.use(config => {
    config.headers.Authorization = "Bearer " + token;
    return config;
  });

  serverInstance.interceptors.request.use(config => {
    config.headers.Authorization = "Bearer " + token;
    return config;
  });
};

export const asyncValidate = (values /*, dispatch */) => {
  return sleep(1000).then(() => {
    // simulate server latency
    if (["foo@foo.com", "bar@bar.com"].includes(values.email)) {
      // eslint-disable-next-line no-throw-literal
      throw { email: "Email already Exists" };
    }
  });
};

export const asyncSubmitToServer = async function(values) {
  const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));
  await sleep(2000);
  // destructuring to create a copy only create shallow copy, thus need to use JSON functions
  let serverResource = JSON.parse(JSON.stringify(values));
  // serverResource.name = humanNameConvertToFhir(serverResource.name);
  window.alert(`You submitted:\n\n${JSON.stringify(serverResource)}`);
};

export const getAccessToken = async () => {
  try {
    let authResponse = await instance.get(`/auth`);
    let access_token = authResponse.data.access_token;
    let message = authResponse.data;
    delete message.access_token;
    return {
      code: authResponse.status,
      message: message,
      access_token: access_token,
    };
  } catch (error) {
    return handleError(error);
  }
};

export const getOrganization = async user => {
  try {
    let organizationResponse = await instance.get(
      `/organization?_id=${user.organizationId}`
    );
    return {
      code: organizationResponse.status,
      organization: organizationResponse.data,
    };
    // let userResponse = await instance.post(`user/getTeam`, organization.team);
    // organization.team = userResponse.data;
  } catch (error) {
    return handleError(error);
  }
};

export const getUser = async email => {
  try {
    let userResponse = await instance.get(`/user?email=${email}`);
    return {
      code: userResponse.status,
      message: userResponse.data,
    };
  } catch (error) {
    return handleError(error);
  }
};

export const updateUser = async user => {
  try {
    let userResponse = await instance.put(`/user`, user);
    return {
      code: userResponse.status,
      message: userResponse.data,
    };
  } catch (error) {
    return handleError(error);
  }
};

export const createUser = async user => {
  try {
    let userResponse = await instance.post(`/user`, user);
    await instance.put(`/user`, user);
    return {
      code: userResponse.status,
      message: userResponse.data,
    };
  } catch (error) {
    return handleError(error);
  }
};

export const getAvailabilityForPractitioner = async practitionerId => {
  try {
    const userResponse = await instance.get(
      `/user?practitionerId=${practitionerId}`
    );
    return {
      practitionerUser: userResponse.data,
      code: userResponse.status,
      message: userResponse.statusText,
      availability: userResponse.data ? userResponse.data.availability : null,
      timezone:
        userResponse.data && userResponse.data.availability
          ? userResponse.data.availability.timezone
          : null,
    };
  } catch (error) {
    return handleError(error);
  }
};

export const createTask = async task => {
  const timestamp = moment.utc().format();
  let fullTask = { ...task };
  fullTask.dateCreated = task.dateCreated ? task.dateCreated : timestamp;
  fullTask.lastUpdated = task.lastUpdated ? task.lastUpdated : timestamp;
  fullTask.activity = task.activity
    ? task.activity
    : [{ timestamp: timestamp, initialCommit: true }];

  try {
    const taskResponse = await serverInstance.post(`/tasks`, fullTask);
    return {
      code: taskResponse.status,
      task: taskResponse.data,
    };
  } catch (error) {
    return handleError(error);
  }
};

export const createForm = async form => {
  try {
    let formResponse = await instance.post(`/form`, form);
    return {
      code: formResponse.status,
      data: formResponse.data,
    };
  } catch (error) {
    return handleError(error);
  }
};

export const getFormBundle = async bundleId => {
  try {
    let formBundleResponse = await instance.get(`/formBundle?id=${bundleId}`);
    // let formBundle =
    //   Array.isArray(formBundleResponse.data) && formBundleResponse.data[0];
    return {
      code: formBundleResponse.status,
      data: formBundleResponse.data,
    };
  } catch (error) {
    return handleError(error);
  }
};

export const getOptionsFromServer = async (searchTerm, path) => {
  try {
    // let optionsResponse = await instance.get(`/search/${path}`, {
    //   params: {
    //     query: { search: searchTerm },
    //   },
    // });
    // console.debug("options response", optionsResponse);
    // return {
    //   code: optionsResponse.status,
    //   data: optionsResponse.data,
    // };
    let options = [];
    let status = 204;
    let tags;
    let snomed = false;
    switch (path) {
      case "/answer-options/problems/snomed": {
        tags = "disorder";
        snomed = true;
        break;
      }
      case "/answer-options/procedures/snomed": {
        tags = "procedure";
        snomed = true;
        break;
      }
      case "/answer-options/all/snomed": {
        tags = "disorder,finding,situation,procedure,event";
        snomed = true;
        break;
      }
      case "/answer-options/drugs": {
        if (searchTerm.length >= 3) {
          let rxResponse = await getMedicationOptions(searchTerm);
          let results = rxResponse.options;
          status = rxResponse.code;
          if (status < 400 && Array.isArray(results) && results.length) {
            results.forEach(element => {
              options.push({
                value: element.Name,
                label: element.Name,
                strengthOptions: element.Strength,
              });
            });
          }
        }
        break;
      }
      case "/answer-options/allergies": {
        if (searchTerm.length >= 3) {
          let allergyResponse = await getAllergyOptions(searchTerm);
          let results = allergyResponse.options;
          status = allergyResponse.code;
          if (status < 400 && Array.isArray(results) && results.length > 0) {
            options = filterAllergyOptions(results);
          }
        }
        break;
      }
      case "/answer-options/states": {
        status = 200;
        options = filterOptions(stateOptions, searchTerm);
        break;
      }
      case "/answer-options/relationship_types": {
        status = 200;
        options = filterOptions(relationshipTypes, searchTerm);
        break;
      }
      case "/answer-options/languages": {
        status = 200;
        options = filterOptions(languages, searchTerm);
        break;
      }
      default: {
        break;
      }
    }
    if (snomed) {
      if (searchTerm.length >= 3) {
        const snomedResponse = await serverInstance.get(`/standards/snomed`, {
          params: {
            search: searchTerm,
            type: tags,
          },
        });

        if (
          snomedResponse &&
          snomedResponse.data &&
          snomedResponse.data.code &&
          snomedResponse.data.code < 400
        ) {
          let results = snomedResponse.data.results;
          if (Array.isArray(results) && results.length) {
            results.forEach(element => {
              options.push({
                value: element.concept.conceptId,
                snomed: element.concept.conceptId,
                label: element.term,
                system: "http://www.snomed.org",
              });
            });
          }
        }
        status = snomedResponse.data.code;
      }
    }
    return {
      code: status,
      data: options,
    };
  } catch (error) {
    return handleError(error);
  }
};

const filterOptions = (options, search) => {
  return options.filter(element => {
    let regex = new RegExp(search, "gi");
    return regex.test(element.label);
  });
};

const filterAllergyOptions = options => {
  return options.map(element => {
    return { value: element, label: element.Name };
  });
};

export const sendEmailNotification = async (
  eventName,
  appointmentStartTime,
  organization,
  practitioner,
  patient,
  newPatient,
  apptMeta,
  availability
) => {
  const practitionerEmail = getPractitionerEmail(practitioner);
  const location =
    apptMeta && apptMeta.videoVisit && apptMeta.videoUrl
      ? apptMeta.videoUrl
      : `${organization.addressLine1}${
          organization.addressLine2 ? `, ${organization.addressLine2}` : ""
        }, ${organization.city}, ${organization.state}, ${
          organization.zipcode
        }`;
  // let patientName = getOfficialName(patient.name);
  // let telecom = getTelecomInfo(patient.telecom);
  const eventTime =
    availability && availability.timezone
      ? moment(appointmentStartTime)
          .tz(availability.timezone)
          .format("lll z")
      : organization.timezone
      ? moment(appointmentStartTime)
          .tz(organization.timezone)
          .format("lll z")
      : moment(appointmentStartTime).format("lll");
  const message = {
    subject: newPatient
      ? "New Patient & Appointment Created"
      : "Patient Booked New Appointment",
    toEmail: practitionerEmail,
    fromEmail: "info@akutehealth.com",
    text: `Appointment: ${eventName}\nDate: ${eventTime}`,
    html: `<div><a clicktracking=off href="${
      process.env.REACT_APP_AKUTE_URL
    }/patient/${
      patient.id
    }/dashboard" target="_blank" rel="noopener noreferrer"><h4>Go to Patient</h4></a>
    <p><strong>Appointment: </strong>${eventName}<strong><br />Date: </strong>${eventTime}<br />
      <a clicktracking=off href="${process.env.REACT_APP_AKUTE_URL}/calendar/${
      apptMeta && apptMeta._id ? apptMeta._id : ""
    }" target="_blank" rel="noopener noreferrer">Go to Appointment</a></p>
      <p><strong>Location: </strong>${
        apptMeta && apptMeta.videoVisit && apptMeta.videoUrl
          ? `<a clicktracking=off href="${location}" target="_blank" rel="noopener noreferrer">${location}</a></div>`
          : location
      }</p>`,
  };
  try {
    const emailResponse = await serverInstance.post(`/emails`, message);
    return {
      code: emailResponse.status,
      message: emailResponse.data,
    };
  } catch (error) {
    return handleError(error);
  }
};

export const createApptMeta = async apptMeta => {
  try {
    let apptMetaResponse = await instance.post(`/apptMeta`, apptMeta);
    return {
      code: apptMetaResponse.status,
      apptMeta: apptMetaResponse.data,
    };
  } catch (error) {
    return handleError(error);
  }
};

export const auth = async ({ email, password }) => {
  return new Promise((resolve, reject) =>
    setTimeout(() => {
      if (email === "" || password === "") {
        return reject("That email and password combination is incorrect");
      } else if (email === "z" && password === "z") {
        return reject(
          "There was an error connecting to the server. Please try again."
        );
      }
      resolve();
    }, 1000)
  );
};

const handleError = error => {
  console.error(error);
  if (error.response) {
    let errorResponse = error.response; // JSON.parse(error);
    let errorMessage = "";
    if (
      errorResponse.data &&
      errorResponse.data.issue &&
      errorResponse.data.issue[0].diagnostics
    ) {
      errorMessage = errorResponse.data.issue[0].diagnostics;
    } else if (errorResponse.data) {
      errorMessage = errorResponse.data;
    } else if (errorResponse.statusText) {
      errorMessage = errorResponse.statusText;
    } else {
      errorMessage = "Error";
    }
    return {
      code: errorResponse.status,
      message: errorMessage,
      error: errorMessage,
    };
  } else {
    return {
      code: 400,
      message:
        typeof error === "string"
          ? error
          : "Internal Server Error. Please contact support.",
      error:
        typeof error === "string"
          ? error
          : "Internal Server Error. Please contact support.",
    };
  }
};
