// fhir
// schedule
export const REQUEST_SCHEDULE = "REQUEST_SCHEDULE";
export const FETCH_SCHEDULE_SUCCEEDED = "FETCH_SCHEDULE_SUCCEEDED";
export const FETCH_SCHEDULE_FAILED = "FETCH_SCHEDULE_FAILED";
// patients
export const REQUEST_PATIENTS = "REQUEST_PATIENTS";
export const FETCH_PATIENTS_SUCCEEDED = "FETCH_PATIENTS_SUCCEEDED";
export const FETCH_PATIENTS_FAILED = "FETCH_PATIENTS_FAILED";
export const SET_PATIENT = "SET_PATIENT";
export const SET_PATIENT_CREATED = "SET_PATIENT_CREATED";
export const SET_PATIENTS_FETCHING = "SET_PATIENTS_FETCHING";
export const EDIT_PATIENTS = "EDIT_PATIENTS";
//practitioners
export const REQUEST_PRACTITIONERS = "REQUEST_PRACTITIONERS";
export const FETCH_PRACTITIONERS_SUCCEEDED = "FETCH_PRACTITIONERS_SUCCEEDED";
export const FETCH_PRACTITIONERS_FAILED = "FETCH_PRACTITIONERS_FAILED";
// appointments
export const SET_APPOINTMENT_SUCCESS = "SET_APPOINTMENT_SUCCESS";
// export const REQUEST_CREATE_SLOT_APPOINTMENT = "REQUEST_CREATE_SLOT_APPOINTMENT";
// export const CREATE_SLOT_APPOINTMENT_SUCCEEDED =
//   "CREATE_SLOT_APPOINTMENT_SUCCEEDED";
export const UPDATE_EVENT_SUCCESS = "UPDATE_EVENT_SUCCESS";
export const REQUEST_UPDATE_EVENT = "REQUEST_UPDATE_EVENT";
export const SELECT_PRACTITIONER = "SELECT_PRACTITIONER";
export const REQUEST_SETTINGS = "REQUEST_SETTINGS";
export const LOGIN_SUCCEEDED = "LOGIN_SUCCEEDED";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const USER_LOGOUT = "USER_LOGOUT";
export const FETCH_SETTINGS_SUCCEEDED = "FETCH_SETTINGS_SUCCEEDED";
export const FETCH_SETTINGS_FAILED = "FETCH_SETTINGS_FAILED";
export const SET_EVENT_TYPE = "SET_EVENT_TYPE";
/* settings */
// user
export const SET_USER = "SET_USER";
export const REQUEST_USER = "REQUEST_USER";
export const FETCH_USER_FAILED = "FETCH_USER_FAILED";
export const FETCH_USER_SUCCEEDED = "FETCH_USER_SUCCEEDED";
//auth
export const FETCH_ACCESS_TOKEN_SUCCESS = "FETCH_ACCESS_TOKEN_SUCCESS";
export const UNAUTHORIZED_ACCESS = "UNAUTHORIZED_ACCESS";
// organization
export const SET_ORGANIZATION = "SET_ORGANIZATION";
export const REQUEST_ORGANIZATION = "REQUEST_ORGANIZATION";
export const FETCH_ORGANIZATION_FAILED = "FETCH_ORGANIZATION_FAILED";
export const FETCH_ORGANIZATION_SUCCEEDED = "FETCH_ORGANIZATION_SUCCEEDED";
// events
export const SET_EVENT = "SET_EVENT";
export const REQUEST_EVENTS = "REQUEST_EVENTS";
export const FETCH_EVENTS_FAILED = "FETCH_EVENTS_FAILED";
export const FETCH_EVENTS_SUCCEEDED = "FETCH_EVENTS_SUCCEEDED";
// availability
export const REQUEST_AVAILABLE_SLOTS = "REQUEST_AVAILABLE_SLOTS";
export const FETCH_AVAILABLE_SLOTS_SUCCESS = "FETCH_AVAILABLE_SLOTS_SUCCESS";
export const FETCH_AVAILABLE_SLOTS_FAILED = "FETCH_AVAILABLE_SLOTS_FAILED";
export const SET_SCHEDULE_FETCHING = "SET_SCHEDULE_FETCHING";
// slots
export const REQUEST_SLOTS = "REQUEST_SLOTS";
// general
export const RESET_STORE = "RESET_STORE";
// dosespot
export const REQUEST_DOSESPOT_CREDENTIALS = "REQUEST_DOSESPOT_CREDENTIALS";
export const FETCH_DOSESPOT_CREDENTIALS_FAILED =
  "FETCH_DOSESPOT_CREDENTIALS_FAILED";
export const FETCH_DOSESPOT_CREDENTIALS_SUCCEEDED =
  "FETCH_DOSESPOT_CREDENTIALS_SUCCEEDED";
// questionnaires
export const ADD_PENDING_QUESTIONNAIRE_RESPONSE =
  "ADD_PENDING_QUESTIONNAIRE_RESPONSE";
